import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useHistory } from 'react-router-dom'; // Use this for React Router v5

const BarChartComponent = ({ data }) => {


  const history = useHistory(); // For React Router v5

  const handleBarClick = (month) => {
    // Use either history.push or navigate
     history.push(`/dashboard/countrymapmonth?month=${month}`);
     
  };


  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalviews" name="Total Streams" fill="#6ebdd1" barSize={40} onClick={(data) => handleBarClick(data.Month)} />
        <Bar dataKey="totalearnings" name="Total Earnings" fill="#afc979" barSize={40} onClick={(data) => handleBarClick(data.Month)}  />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
